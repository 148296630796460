.header {
  z-index: 1000;
  background-color: #fff;
  width: 100%;
  position: fixed;
  top: 0;
  box-shadow: 0 2px 2px #0000001a;
}

.header a {
  color: #424242;
  font-size: 1.2rem;
}

.header a:hover {
  -webkit-text-decoration: underline #424242;
  text-decoration: underline #424242;
}

.header-content {
  justify-content: space-between;
  align-items: center;
  padding: 0 1.6rem;
  display: flex;
}

.header-navigation {
  font-family: var(--font-manrope);
  flex-direction: row;
  align-items: center;
  gap: 2.1rem;
  display: flex;
}

.atx2s-logo {
  font-family: var(--font-roboto);
  align-items: center;
  font-size: 1.8rem;
  font-weight: 900;
  display: flex;
}

.atx2s-logo span {
  color: #333;
  margin-left: .2em;
}

.chrome-logo-icon {
  width: 40px;
  height: 40px;
}

.burger-menu {
  cursor: pointer;
  order: 3;
  font-size: 2rem;
  display: none;
}

.cta-button1, .cta-button2 {
  background-color: var(--color-blueviolet);
  color: #fff;
  cursor: pointer;
  font-size: 1.2rem;
  font-family: var(--font-manrope);
  text-transform: uppercase;
  border: none;
  border-radius: .5rem;
  align-items: center;
  gap: .7rem;
  padding: .7rem 1rem;
  display: flex;
}

.mobile-navigation {
  font-family: var(--font-manrope);
  text-align: center;
  z-index: 999;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 75px);
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.mobile-navigation .nav-link {
  padding: .5rem 1rem;
  font-size: 2rem;
}

.hero {
  text-align: center;
  color: #fff;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
  display: flex;
}

.wrapper-hero-background-image {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -10rem;
  left: 0;
}

.hero-wrapper {
  justify-content: center;
  max-width: 90%;
  margin-top: 3rem;
  display: flex;
}

.hero-screen {
  justify-content: center;
  width: 100%;
  max-width: 800px;
  height: auto;
  padding: 2rem;
  display: flex;
  position: relative;
}

.hero-video {
  object-fit: contain;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  padding: 0 calc(2rem + 4%) 7%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero-screen img {
  object-fit: contain;
  z-index: 1;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.hero-content {
  z-index: 1;
  color: #424242;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  max-width: 45%;
  padding: 2rem;
  display: flex;
  position: relative;
}

.hero-text {
  flex-direction: column;
  display: flex;
}

.hero-text h1 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 130%;
  font-family: var(--font-roboto);
  text-align: left;
}

.hero-text h3 {
  font-family: var(--font-manrope);
  font-size: var(--font-size-5xl);
  color: gray;
  text-align: left;
  margin-top: 2rem;
  font-weight: 400;
  line-height: 175%;
}

.hero-image-icon {
  z-index: -1;
  width: 33%;
  height: auto;
  margin-top: -9rem;
  margin-bottom: -9.9rem;
}

.hero-cta h1 {
  font-weight: 400;
  font-family: var(--font-space-mono);
  font-size: var(--font-size-13xl);
  margin-bottom: 4rem;
}

.cta-savoir-plus, .cta-savoir-plus-arrows {
  flex-direction: column;
  display: flex;
}

.cta-savoir-plus {
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.cta-savoir-plus a {
  color: inherit;
}

.cta-savoir-plus a:hover {
  text-decoration: none;
}

.cta-savoir-plus-arrows {
  margin-top: 2rem;
}

.cta-savoir-plus-text {
  padding: var(--padding-3xs);
  color: var(--color-mediumspringgreen);
  font-family: var(--font-manrope);
  font-size: var(--font-size-5xl);
  cursor: pointer;
  letter-spacing: .2em;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cta-button1 {
  margin-top: .5rem;
}

.advantages {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.section-title {
  text-align: center;
  letter-spacing: .05em;
  font-family: var(--font-manrope);
  color: #424242;
  flex-direction: column;
  gap: 1.2rem;
  max-width: 80%;
  margin-top: 3rem;
  margin-bottom: 6rem;
  font-size: 2rem;
  display: flex;
}

.advantage-card-container {
  grid-template-columns: repeat(3, 1fr);
  gap: 2.7rem;
  max-width: 70%;
  padding: .3rem 0;
  display: grid;
}

.advantage-card {
  text-align: center;
  font-family: var(--font-manrope);
  background: #fafafa;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 1.5rem 1rem;
  display: flex;
  box-shadow: 0 2px 4px #00000040;
}

.advantage-card-text {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
  display: flex;
}

.advantage-card-icon {
  margin-bottom: .5rem;
  position: relative;
}

.advantage-card-icon-circle {
  background-color: var(--color-mediumspringgreen);
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  position: relative;
}

.advantage-card-icon img {
  object-fit: cover;
  z-index: 1;
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: calc(50% - 1.5rem);
  left: calc(50% - 1.5rem);
  margin: 0 !important;
}

.advantage-card-title {
  color: #424242;
  margin-bottom: .5rem;
  font-size: 1.3rem;
  font-weight: 800;
}

.advantage-card-content {
  color: #606060;
  font-size: 1rem;
  line-height: 1.8rem;
}

.how-it-works {
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 3rem;
  display: flex;
  overflow: hidden;
}

.section-title h1 {
  text-align: center;
}

.section-title span {
  font-size: 1.2rem;
}

.how-it-works-bg {
  z-index: -1;
  right: 0;
}

.how-it-works-container {
  justify-content: flex-end;
  width: 90%;
  display: flex;
  position: relative;
}

.steps-container {
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
  display: flex;
  position: absolute;
  top: 50%;
  left: 9%;
  transform: translateY(-50%);
}

.steps-card {
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  display: flex;
}

.steps-card-number {
  justify-content: center;
  align-items: center;
  gap: var(--gap-3xs);
  background-color: var(--color-blueviolet);
  z-index: 0;
  color: #fff;
  font-size: 2.4rem;
  font-family: var(--font-manrope);
  border-radius: 50%;
  flex-direction: column;
  flex-shrink: 0;
  width: 6rem;
  height: 6rem;
  display: flex;
  position: relative;
}

.steps-card-content {
  background-color: var(--color-gray-700);
  border: 2px solid var(--color-blueviolet);
  gap: var(--gap-7xl);
  text-align: left;
  color: var(--pallet-1-dark);
  font-family: var(--font-manrope);
  border-radius: .5rem;
  flex-direction: column;
  max-width: 25rem;
  padding: 1.5rem;
  font-size: 1.2rem;
  line-height: 175%;
  display: flex;
  overflow: hidden;
}

.steps-card-content h3 {
  font-size: 1.8rem;
}

.howtos {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.tutos-container {
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem 2rem;
  width: 80%;
  margin-bottom: 3rem;
  display: grid;
}

.tuto-video {
  border-radius: .5rem;
  width: 100%;
  padding-top: 56.25%;
}

.tuto-video iframe {
  border-radius: .5rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.tuto-video p {
  z-index: 10;
  font-family: var(--font-manrope);
  width: 100%;
  padding-top: .3rem;
  padding-bottom: .9rem;
  position: absolute;
}

.pricing {
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
  display: flex;
  overflow: hidden;
}

.plan-container {
  justify-content: center;
  align-items: center;
  gap: 10rem;
  width: 100%;
  display: flex;
}

.plan {
  box-sizing: border-box;
  text-align: center;
  aspect-ratio: 21 / 30;
  font-family: var(--font-manrope);
  flex-direction: column;
  justify-content: space-between;
  min-width: 21rem;
  padding: 1.5rem 1.2rem;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.plan hr {
  border-bottom: 2px dashed #fff8;
  display: block;
}

.plan-card-footer {
  justify-content: center;
  align-items: center;
  gap: 3px;
  display: flex;
  position: relative;
}

.plan-hint {
  cursor: pointer;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  color: #424242;
  text-align: center;
  z-index: 1;
  opacity: 0;
  background-color: #f5f5f5;
  width: 84%;
  padding: .3rem;
  transition: opacity .3s;
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip small {
  margin-top: 6px;
}

.plan-hint:hover ~ .tooltip {
  visibility: visible;
  opacity: 1;
}

.plan-hint:hover {
  text-decoration: underline;
}

.sm-plan {
  color: #424242;
  fill: #424242;
  opacity: 0;
  background-color: #45fcba;
  transform: rotate(12deg);
}

.pro-plan {
  color: #fff;
  fill: #fff;
  opacity: 0;
  background-color: #5e45fc;
  transform: rotate(-6deg);
}

.plan-icon {
  width: 100%;
  font-size: 2rem;
}

.plan-title {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .3rem;
  display: flex;
}

.plan-title div {
  flex-direction: row;
  align-items: center;
  gap: .3rem;
  display: flex;
}

.plan-title h3 {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 125%;
}

.plan-title h4 {
  font-size: 1.2rem;
  font-weight: 300;
}

.plan-description {
  font-size: 1.2rem;
  list-style-type: none;
}

.plan-description li {
  padding: .3rem 0;
  font-size: 1.3rem;
}

.plan-price {
  font-size: 3rem;
}

.pricing-bg {
  z-index: -1;
  margin-top: -15rem;
}

.faq {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.faq-container {
  font-size: 1.5rem;
  font-family: var(--font-manrope);
  color: #424242;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  width: 80%;
  padding: .3rem 0;
  display: flex;
}

.faq-item {
  border: 1px solid var(--color-gray-500);
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: var(--gap-4xl);
  cursor: pointer;
  opacity: 1;
  border-radius: .5rem;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem 2rem;
  scroll-margin-top: 80px;
  display: flex;
  box-shadow: 0 2px 4px #00000040;
}

.faq-item a {
  color: #5e45fc;
  font-weight: 500;
}

.faq-item a:hover {
  text-decoration: underline;
}

.faq-heading {
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.faq-question {
  color: #424242;
  font-weight: 500;
  line-height: 2rem;
}

.faq-arrow-icon {
  object-fit: cover;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  transition: transform .3s ease-in-out;
}

.faq-arrow-icon.flipped {
  transform: scaleY(-1);
}

.faq-answer {
  font-size: var(--font-size-xl);
  letter-spacing: .02em;
  color: var(--color-gray-800);
  text-align: justify;
  align-self: stretch;
  max-height: 0;
  line-height: 155%;
  transition: max-height .3s ease-out;
  overflow: hidden;
}

.faq-answer ul {
  max-width: 100%;
  margin: .5em 0 .5em .5rem;
  list-style-position: inside;
}

.faq-answer ul ul {
  margin-left: 3rem;
  list-style-position: outside;
}

.faq-answer h4 {
  margin-bottom: .5em;
  font-size: 1.3rem;
}

.faq-answer p, .faq-answer ul li {
  margin-bottom: .5em;
}

.faq-answer.open {
  max-height: 1000vh;
  transition: max-height .3s ease-out;
}

.footer {
  color: #f5f5f5;
  font-family: var(--font-manrope);
  background-color: #424242;
  width: 100%;
  margin-top: 3rem;
  padding: 1rem 2rem;
}

.footer-content {
  text-align: center;
  margin: 0 auto;
}

.footer-social-icons {
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;
  display: flex;
}

.footer-links {
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 0;
  list-style: none;
  display: flex;
  font-size: 1rem !important;
}

.footer-links a {
  color: inherit;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.social-icon svg {
  fill: #f5f5f5;
  width: 1.8rem;
  height: 1.8rem;
  transition: transform .3s ease-in-out;
  transform: scale(1);
}

.social-icon svg:hover {
  transition: transform .3s ease-in-out;
  transform: scale(1.2);
}

.footer-content p {
  margin: .9rem 0;
}

.footer-text {
  font-size: 1.5rem;
}

.footer-copyright {
  font-size: .9rem;
}

.footer-copyright a {
  color: inherit;
  transition: all .3s;
}

.footer-copyright a:hover {
  text-decoration: underline;
  transition: all .3s;
}

.mobile-message {
  opacity: 0;
  text-align: center;
  z-index: 999;
  font-family: var(--font-manrope);
  z-index: 1000;
  background-color: #f2f2f2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .6rem;
  width: 100%;
  height: 100vh;
  padding: 1rem;
  font-size: 1.2rem;
  transition: opacity 1.7s;
  display: none;
  position: fixed;
  overflow: hidden;
}

.mobile-message.visible {
  opacity: 1;
  display: flex;
}

.mobile-message > div {
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: .3rem;
  display: flex;
}

.mobile-message a {
  color: var(--color-blueviolet);
}

.mobile-message form {
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;
  display: flex;
}

.mobile-message input {
  color: #424242;
  border: 1px solid #a4fada;
  border-radius: 6px;
  outline: none;
  width: 100%;
  padding: .6em;
  font-size: 1em;
}

.mobile-message button {
  color: #5e45fc;
  cursor: pointer;
  letter-spacing: .1em;
  background-color: #fff;
  border: 1px solid #5e45fc;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 9px;
  font-size: 1.2rem;
  transition: all .3s;
  display: flex;
  position: relative;
}

.mobile-message span {
  text-align: right;
  width: 100%;
  font-size: .8em;
  position: absolute;
  top: .5em;
  right: .6em;
}

#emailStatusMessage {
  margin-top: .6rem;
  font-size: .8em;
}

#cta-arrow1.animate {
  animation: 1s infinite alternate forwards fade-in;
}

#cta-arrow2.animate {
  animation: 1s .3s infinite alternate forwards fade-in;
}

#cta-arrow3.animate {
  animation: 1s .6s infinite alternate forwards fade-in;
}

.hero-cta, .cta {
  opacity: 0;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  display: flex;
}

.scale-in {
  opacity: 0;
  transition: transform 1s, opacity .3s;
  transform: scale(.3);
}

.scale-in-view {
  opacity: 1;
  transform: scale(1);
}

.slide-in-top {
  opacity: 0;
  transition: transform .7s, opacity .3s;
  transform: translateY(-100%);
}

.slide-in-bottom {
  opacity: 0;
  transition: transform .7s, opacity .3s;
  transform: translateY(50%);
}

.cta {
  transform: translateY(100px);
}

.slide-in-left {
  opacity: 0;
  transition: transform .7s, opacity .3s;
  transform: translateX(-100%);
}

.slide-in-right {
  opacity: 0;
  transition: transform .7s, opacity .3s;
  transform: translateX(100%);
}

.slide-in-view {
  opacity: 1;
  transition: opacity .5s ease-in-out, transform .5s ease-in-out;
  transform: scale(1)translateY(0)translateX(0);
}

.instant-in-place {
  opacity: 1;
  transition-duration: 0s;
  transform: scale(1)translateY(0)translateX(0)rotate(0);
}

@keyframes slideInLeftAndRotate {
  from {
    opacity: 0;
    transform: translateX(-50%)rotate(12deg);
  }

  to {
    opacity: 1;
    transform: translateX(0)rotate(12deg);
  }
}

@keyframes slideInLeftWithoutRotation {
  from {
    opacity: 0;
    transform: translateX(-50%)rotate(12deg);
  }

  to {
    opacity: 1;
    transform: translateX(0)rotate(0);
  }
}

.sm-plan-animate {
  animation: .5s ease-out forwards slideInLeftAndRotate;
}

@keyframes slideInBottomAndRotate {
  from {
    opacity: 0;
    transform: translateY(50%)rotate(21deg);
  }

  to {
    opacity: 1;
    transform: translateY(0)rotate(-6deg);
  }
}

@keyframes slideInRightWithoutRotation {
  from {
    opacity: 0;
    transform: translateX(50%)rotate(-6deg);
  }

  to {
    opacity: 1;
    transform: translateX(0)rotate(0);
  }
}

.pro-plan-animate {
  animation: .5s ease-out forwards slideInBottomAndRotate;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  animation-name: tada;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

.tada-view {
  opacity: 1;
  animation-name: tada;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

@media (width >= 131.25rem) {
  .how-it-works-container {
    width: 80%;
  }

  .advantage-card-container {
    max-width: 60%;
  }
}

@media (width <= 93.75rem) {
  .how-it-works-container {
    width: 100%;
  }
}

@media (width <= 87.5rem) {
  .steps-container {
    left: 3%;
  }

  .how-it-works-container {
    width: 100%;
  }

  .hero-wrapper {
    margin-top: 0;
  }

  .advantage-card-container {
    max-width: 85%;
  }
}

@media (width <= 81.25rem) {
  .hero-content {
    max-width: 54%;
  }

  .how-it-works-bg {
    width: 80%;
  }
}

@media (width <= 75rem) {
  .hero-image-icon {
    width: 50%;
  }

  .header-navigation {
    margin-left: 1.5rem;
  }

  .cta-button2 {
    display: none;
  }

  .advantage-card-container {
    gap: 2rem;
  }
}

@media (width <= 68.75rem) {
  .hero-wrapper {
    flex-direction: column-reverse;
    align-items: center;
  }

  .hero-content {
    align-items: center;
    max-width: 90%;
  }

  .hero-screen {
    padding: 2rem;
  }

  .advantage-card-container {
    max-width: 90%;
  }

  .how-it-works-container {
    flex-direction: column;
    align-items: center;
  }

  .how-it-works-bg {
    width: 70%;
  }

  .steps-container {
    position: static;
    transform: none;
  }

  .steps-card {
    flex-direction: column;
    align-items: center;
  }

  .steps-card-content {
    text-align: center;
  }

  .plan-container {
    gap: 8rem;
  }

  .hero-text h1, .hero-text h3 {
    text-align: center;
  }

  .header-navigation {
    gap: 1rem;
  }

  .pricing-bg {
    margin-top: -18rem;
  }
}

@media (width <= 56.25rem) {
  .hero-content {
    max-width: 90%;
    padding: 0 0 2rem;
  }

  .hero-image-icon {
    width: 70%;
  }

  .advantage-card-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .pricing-bg {
    margin-top: -15rem;
  }

  .faq-question {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .faq-item {
    padding: 1rem 1.2rem;
  }

  .burger-menu {
    display: block;
  }

  .header-navigation {
    display: none;
  }

  .nav-link {
    padding: .5rem 1rem;
  }

  .header-content {
    justify-content: space-between;
  }
}

@media (width <= 48rem) {
  .hero-text h1 {
    font-size: 3rem;
  }

  .hero-text h3 {
    font-size: 1.3rem;
  }

  .hero-image-icon {
    width: 85%;
  }

  .advantage-card-container {
    grid-template-columns: 1fr;
    max-width: 70%;
  }

  .tutos-container {
    grid-template-columns: 1fr;
  }

  .pro-plan-animate {
    margin-top: 0;
    animation: .5s ease-out forwards slideInRightWithoutRotation;
  }

  .sm-plan-animate {
    margin-top: 0;
    animation: .5s ease-out forwards slideInLeftWithoutRotation;
  }

  .plan-container {
    flex-direction: column;
    gap: 6rem;
  }

  .pricing {
    overflow: hidden;
  }

  .pricing-bg {
    margin-top: 0;
  }

  .faq-container {
    width: 90%;
  }
}

@media (width <= 37.25rem) {
  .hero-content {
    max-width: 100%;
  }

  .hero-text h1 {
    line-height: 110%;
  }

  .hero-text h3 {
    line-height: 130%;
  }

  .hero-image-icon {
    width: 100%;
  }

  .advantage-card-container {
    max-width: 85%;
  }

  .steps-card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .steps-card-content {
    text-align: center;
  }

  .how-it-works-bg {
    width: 100%;
  }

  .faq-item {
    padding: .6rem;
  }

  .mobile-message > div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (width <= 28.125rem) {
  .hero-image-icon {
    margin: -5rem;
  }

  .cta-button1 {
    max-width: 90%;
  }

  .plan {
    min-width: none;
    width: 90%;
  }
}

/*# sourceMappingURL=cgl.a52e61d3.css.map */
