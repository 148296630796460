/* Styles for the header */
.header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    /* adjust the color as needed */
    z-index: 1000;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    /* subtle shadow for depth */

}

.header a {
    font-size: 1.2rem;
    color: #424242;
}

.header a:hover {
    text-decoration: underline;
    text-decoration-color: #424242;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.6rem;
}

.header-navigation {
    font-family: var(--font-manrope);
    display: flex;
    /* width: 100%; */
    flex-direction: row;
    align-items: center;
    /* justify-content: space-around; */
    /* max-width: 768px; */
    gap: 2.1rem;
}

/* Logo positioning */
.atx2s-logo {
    display: flex;
    align-items: center;
    font-family: var(--font-roboto);
    font-weight: 900;
    font-size: 1.8rem;
    /* Logo is always on the left */
}

.atx2s-logo span {
    color: #333333;
    /* font-size: 1.5rem; */
    
    margin-left: .2em;

}

.chrome-logo-icon {
    width: 40px;
    height: 40px;
}

/* Burger menu styles - hidden by default */
.burger-menu {
    display: none;
    cursor: pointer;
    font-size: 2rem;
    order: 3;
    /* on the right */
}

/* CTA button styles - visible on larger screens */
.cta-button1,
.cta-button2 {
    /* padding: 1rem 1.2rem; */
    padding: .7rem 1rem;
    background-color: var(--color-blueviolet);
    /* adjust color as needed */
    color: white;
    border: none;
    border-radius: .5rem;
    cursor: pointer;
    font-size: 1.2rem;
    font-family: var(--font-manrope);
    display: flex;
    align-items: center;
    gap: .7rem;
    text-transform: uppercase;
}

/* Mobile navigation styles - hidden by default */
.mobile-navigation {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-family: var(--font-manrope);
    top: 100%;
    /* Positioned right below the header */
    left: 0;
    width: 100%;
    height: calc(100vh - 75px);
    text-align: center;
    background-color: white;
    /* Match the header color */
    z-index: 999;
    /* ensure it's above other content */
}

/* Styles for the navigation links in the mobile menu */
.mobile-navigation .nav-link {
    /* display: block; */
    padding: .5rem 1rem;
    font-size: 2rem;

}

.hero {
    /* position: relative; */
    margin-top: 75px;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.wrapper-hero-background-image {
    position: absolute;
    /* top: 1rem; */
    top: -10rem;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* Send to back */
}

.hero-wrapper {
    display: flex;
    margin-top: 3rem;
    /* align-items: flex-start; */
    justify-content: center;
    max-width: 90%;
}

.hero-screen {
    position: relative;
    width: 100%; /* Take up full width */
    max-width: 800px; /* Adjust as necessary */
    height: auto; /* Ensure height is dynamic */
    padding: 2rem;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    /* background-image: url('./public/screen.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; */
}

.hero-video {
    position: absolute;
    max-width: 100%; /* Adjust based on the screen size */
    max-height: 100%;
    object-fit: contain; /* Maintain aspect ratio */
    width: auto;
    height: auto;
    padding: 0 calc(2rem + 4%) 7%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the video */
}

.hero-screen img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain; /* Maintain aspect ratio */
    z-index: 1;
}

.hero-content {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #424242;
    max-width: 45%;
    padding: 2rem;
    gap: 2rem;
}

.hero-text {
    /* margin-top: 3rem; */
    /* margin-bottom: 3rem; */
    /* Space between text and image */
    /* max-width: 50%; */
    /* padding: 7%; */
    display: flex;
    flex-direction: column;
}

.hero-text h1 {
    font-size: 4rem;
    line-height: 130%;
    font-weight: 700;
    font-family: var(--font-roboto);
    text-align: left;
}

.hero-text h3 {
    font-family: var(--font-manrope);
    font-size: var(--font-size-5xl);
    font-weight: 400;
    color: grey;
    line-height: 175%;
    margin-top: 2rem;
    /* padding: 0 5%; */
    text-align: left;
}


.hero-image-icon {
    width: 33%;
    /* Make sure the image is responsive */
    height: auto;
    margin-top: -9rem;
    margin-bottom: -9.9rem;
    /* position: absolute; */
    z-index: -1;
}

.hero-cta h1 {
    margin-bottom: 4rem;
    font-weight: 400;
    font-family: var(--font-space-mono);
    font-size: var(--font-size-13xl);
}

.cta-savoir-plus,
.cta-savoir-plus-arrows {
    display: flex;
    flex-direction: column;
}

.cta-savoir-plus {
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    /* Link styling */
}

.cta-savoir-plus a {
    color: inherit;
}

.cta-savoir-plus a:hover {
    text-decoration: none;
}

.cta-savoir-plus-arrows {
    margin-top: 2rem;
}

.cta-savoir-plus-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-3xs);
    color: var(--color-mediumspringgreen);
    font-family: var(--font-manrope);
    font-size: var(--font-size-5xl);
    cursor: pointer;
    letter-spacing: 0.2em;
    /* margin-top: 3rem; */
}

.cta-button1 {
    margin-top: .5rem;

}

.advantages {
    /* padding: 2rem; */
    /* position: relative; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.section-title {
    text-align: center;
    /* line-height: 125%; */
    letter-spacing: .05em;
    font-family: var(--font-manrope);
    font-size: 2rem;
    margin-bottom: 6rem; /* Space below title */
    margin-top: 3rem;
    color: #424242;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    max-width: 80%;
}

.advantage-card-container {
    max-width: 70%;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 cards in a row */
    gap: 2.7rem;
    padding: .3rem 0;
    /* align-items: start; */
}

.advantage-card {
    background: #fafafa; /* Card background */
    padding: 1.5rem 1rem;
    box-shadow: 0 2px 4px #00000040;
    border-radius: 8px; /* Optional */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1.5rem;
    font-family: var(--font-manrope);
}

.advantage-card-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    gap: 1rem;
}

.advantage-card-icon {
    margin-bottom: .5rem;
    position: relative;
}

.advantage-card-icon-circle {
    background-color: var(--color-mediumspringgreen);
    border-radius: 50%;
    width: 6rem;
    height: 6rem;
    position: relative;
}

.advantage-card-icon img {
    object-fit: cover;
    z-index: 1;
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: calc(50% - 1.5rem);
    left: calc(50% - 1.5rem);
    margin: 0 !important;
}

.advantage-card-title {
    font-size: 1.3rem;
    font-weight: 800;
    margin-bottom: .5rem;
    color: #424242
}

.advantage-card-content {
    font-size: 1rem;
    color: #606060;
    line-height: 1.8rem;
}

.how-it-works {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding-bottom: 3rem;
}

.section-title h1 {
    text-align: center;
    /* Additional styles for the title */
}

.section-title span {
    font-size: 1.2rem;
}

.how-it-works-bg {
    /* position: absolute; */
    right: 0;
    z-index: -1;
    /* Send to back */
}

.how-it-works-container {
    display: flex;
    /* height: 120vh; */
    width: 90%;
    justify-content: flex-end;
    position: relative;
}

.steps-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    /* width: 50vw; */
    position: absolute;
    left: 9%;
    top: 50%;
    transform: translateY(-50%);
}

.steps-card {
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: flex-start;
    justify-content: center ;
    padding: 1rem;
}

.steps-card-number {
    justify-content: center;
    align-items: center;
    gap: var(--gap-3xs);
    flex-direction: column;
    flex-shrink: 0;
    display: flex;
    position: relative;
    background-color: var(--color-blueviolet);
    z-index: 0;
    border-radius: 50%;
    width: 6rem;
    height: 6rem;
    color: white;
    font-size: 2.4rem;
    font-family: var(--font-manrope);
}

.steps-card-content {
    display: flex;
    border-radius: .5rem;
    background-color: var(--color-gray-700);
    border: 2px solid var(--color-blueviolet);
    padding: 1.5rem;
    gap: var(--gap-7xl);
    text-align: left;
    color: var(--pallet-1-dark);
    flex-direction: column;
    overflow: hidden;
    font-family: var(--font-manrope);
    font-size: 1.2rem;
    max-width: 25rem;
    line-height: 175%;
}

.steps-card-content h3 {
    font-size: 1.8rem;
}

.howtos {
    /* position: relative; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
}

.tutos-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 videos in a row */
    gap: 3rem 2rem;
    width: 80%;
    margin-bottom: 3rem;
}

.tuto-video {
    /* position: relative; */
    width: 100%;
    padding-top: 56.25%; /* Aspect ratio of 16:9 (9 / 16 = 0.5625) */
    border-radius: .5rem;
}

.tuto-video iframe {
    position: absolute;
    border-radius: .5rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.tuto-video p {
    position: absolute;
    z-index: 10;
    padding-bottom: .9rem;
    padding-top: .3rem;
    width: 100%;
    /* text-align: center; */
    font-family: var(--font-manrope);
}

.pricing {
    /* position: relative; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow: hidden visible; */
    overflow: hidden;
    padding-bottom: 3rem;
}

.plan-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10rem; /* Space between plans */
    width: 100%;
}

.plan {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* gap: .5rem; */
    padding: 1.5rem 1.2rem;
    /* border-radius: .5rem; */
    text-align: center;
    min-width: 21rem;
    aspect-ratio: 21 / 30;
    font-family: var(--font-manrope);
    box-shadow: 0 4px 4px #00000040;
    
}

.plan hr {
    display: block;
    border-bottom: 2px dashed rgba(255, 255, 255, 0.534);
    /* margin: 1.25rem 0; */
}

.plan-card-footer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

.plan-hint {
    cursor: pointer;
    display: inline-block;
}

.tooltip {
    visibility: hidden;
    width: 84%;
    padding: 0.3rem;
    background-color: whitesmoke;
    color: #424242;;
    text-align: center;
    position: absolute;
    z-index: 1;
    bottom: 125%; 
    left: 50%; 
    transform: translateX(-50%); 
    opacity: 0;
    transition: opacity 0.3s;
    /* border: 2px dashed #424242; */
    /* box-shadow: 0 4px 4px #00000040; */
}

.tooltip small {
    margin-top: 6px;
}

/* .tooltip::after {
    content: "";
    position: absolute;
    top: 100%; 
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #bafae3 transparent transparent transparent; 
} */

.plan-hint:hover ~ .tooltip {
    visibility: visible;
    opacity: 1;
}

.plan-hint:hover {
    text-decoration: underline;
}

.sm-plan {
    transform: rotate(12deg);
    background-color: #45fcba;
    color: #424242;
    fill: #424242;
    opacity: 0;
}

.pro-plan {
    transform: rotate(-6deg);
    background-color: #5e45fc;
    color: white;
    fill: white;
    /* margin-top: 4rem; */
    opacity: 0;
}

.plan-icon {
    width: 100%;
    font-size: 2rem;
}

.plan-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .3rem;
}

.plan-title div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .3rem;
}

.plan-title h3 {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 125%;
}

.plan-title h4 {
    font-size: 1.2rem;
    font-weight: 300;
}

.plan-description {
    list-style-type: none;
    font-size: 1.2rem;
}

.plan-description li {
    padding: .3rem 0;
    font-size: 1.3rem;
    /* font-weight: bold; */
}

.plan-price {
    font-size: 3rem;
}

/* .plan-frequency {
} */

.pricing-bg {
    /* position: absolute;
    right: 0; */
    z-index: -1;
    margin-top: -15rem;
    /* margin-bottom: -15rem; */
    /* Send to back */
}

.faq {
    /* position: relative; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.faq-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;
    font-family: var(--font-manrope);
    color: #424242;
    gap: 1.5rem;
    justify-content: flex-start;
    width: 80%;
    padding: 0.3rem 0;
}

.faq-item {
    align-self: stretch;
    border-radius: .5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid var(--color-gray-500);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1.5rem 2rem;
    gap: var(--gap-4xl);
    cursor: pointer;
    opacity: 1;
    width: 100%;
    scroll-margin-top: 80px;
}

.faq-item a {
    color: #5e45fc;
    font-weight: 500;
}

.faq-item a:hover {
    text-decoration: underline;
}

.faq-heading {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    /* overflow: hidden; */
}

.faq-question {
    font-weight: 500;
    line-height: 2rem;
    color: #424242;
}

.faq-arrow-icon {
    /* position: relative; */
    width: 1.5rem;
    height: 1.5rem;
    /* overflow: hidden; */
    flex-shrink: 0;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.faq-arrow-icon.flipped {
    transform: scaleY(-1);
  }

.faq-answer {
    align-self: stretch;
    /* position: relative; */
    font-size: var(--font-size-xl);
    letter-spacing: 0.02em;
    line-height: 155%;
    color: var(--color-gray-800);
    overflow: hidden;
    max-height: 0;
    transition: max-height .3s ease-out;
    text-align: justify;
}

.faq-answer ul {
    max-width: 100%;
    list-style-position: inside;
    margin: .5em 0;
    margin-left: .5rem;
    /* padding: 10px; */
}

.faq-answer ul ul {
    margin-left: 3rem;
    list-style-position: outside;
}

.faq-answer h4 {
    margin-bottom: .5em;
    font-size: 1.3rem;
}

.faq-answer p {
    margin-bottom: .5em;
}

.faq-answer ul li {
    margin-bottom: .5em;
}

.faq-answer.open {
    max-height: 1000vh;
    transition: max-height .3s ease-out;
}

.footer {
    background-color: #424242;
    color: #f5f5f5;
    width: 100%;
    padding: 1rem 2rem;
    font-family: var(--font-manrope);
    /* position: relative; */
    margin-top: 3rem;
}

.footer-content {
    text-align: center;
    margin: 0 auto;
}

.footer-social-icons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 1rem 0;
}

.footer-links {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
    list-style: none;
    padding: 0;
    font-size: 1rem !important;
}

.footer-links a {
    color: inherit;
    text-decoration: none;
}

.footer-links a:hover {
    text-decoration: underline;
}

.social-icon svg {
    fill: #f5f5f5;
    height: 1.8rem;
    width: 1.8rem;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
}

.social-icon svg:hover {
    /* height: 1.8rem;
    width: 1.8rem; */
    transform: scale(1.2);
    transition: transform 0.3s ease-in-out;
}

.footer-content p {
    margin: 0.9rem 0;
}

.footer-text {
    font-size: 1.5rem;
}

.footer-copyright {
    font-size: .9rem;
}

.footer-copyright a {
    color: inherit;
    transition: 0.3s
}

.footer-copyright a:hover {
    /* color: #45fcba; */
    text-decoration: underline;
    transition: .3s;
}

/* Message to be displayed to mobile users */

.mobile-message {
    height: 100vh;
    opacity: 0;
    overflow: hidden;
    background-color: #f2f2f2;
    text-align: center;
    padding: 1rem;
    z-index: 999;
    position: fixed;
    /* top: 75px; */
    width: 100%;
    font-family: var(--font-manrope);
    font-size: 1.2rem;
    transition: opacity 1.7s ease; /* Transition for smooth effect */
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .6rem;
    z-index: 1000;

}

.mobile-message.visible {
    opacity: 1;
    display: flex;
}

.mobile-message > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: .3rem;
}

.mobile-message a {
    color: var(--color-blueviolet);
}

.mobile-message form {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* padding: 1rem; */
}

.mobile-message input {
    outline: none;
    border: 1px solid #a4fada;
    border-radius: 6px;
    padding: .6em;
    font-size: 1em;
    color: #424242;
    width: 100%;
}

.mobile-message button {
    color: #5E45FC;
    background-color: white;
    font-size: 1.2rem;
    border: 1px solid #5E45FC;
    border-radius: 6px;
    padding: 9px;
    cursor: pointer;
    transition: .3s;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-family: 'Space Mono', monospace; */
    width: 100%;
    letter-spacing: .1em;
}

.mobile-message span {
    width: 100%;
    text-align: right;
    font-size: .8em;
    position: absolute;
    top: .5em;
    right: .6em;
}

#emailStatusMessage {
    font-size: 0.8em;
    margin-top: .6rem;
}
/* 
.mobile-message-icon {
    height: 1.8rem;
    width: 1.8rem;
} */

/* ANIMATIONS */

#cta-arrow1.animate {
    animation: 1s infinite alternate forwards fade-in;
}

#cta-arrow2.animate {
    animation: 1s .3s infinite alternate forwards fade-in;
}

#cta-arrow3.animate {
    animation: 1s .6s infinite alternate forwards fade-in;
}

/* hide elements by default  */

.hero-cta, .cta {
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}



/* Scale-in animations for images */
.scale-in {
    transform: scale(0.3);
    opacity: 0;
    transition: transform 1s, opacity 0.3s;
}
.scale-in-view {
    transform: scale(1);
    opacity: 1;
}

/* Slide-in animations for elements */
.slide-in-top {
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 0.7s, opacity 0.3s;
}
/* .slide-in-top-view {
    transform: translateY(0);
    opacity: 1;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
} */

.slide-in-bottom {
    transform: translateY(50%);
    opacity: 0;
    transition: transform 0.7s, opacity 0.3s;
}

.cta {
    /* margin-top: 9rem; */
    transform: translateY(100px);
}

/* .slide-in-bottom-view {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
} */

.slide-in-left {
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 0.7s, opacity 0.3s;
}

.slide-in-right {
    transform: translateX(100%);
    opacity: 0;
    transition: transform 0.7s, opacity 0.3s;
}

.slide-in-view {
    opacity: 1;
    transform: scale(1) translateY(0) translateX(0);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.instant-in-place {
    opacity: 1;
    transform: scale(1) translateY(0) translateX(0) rotate(0);
    transition-duration: 0s;
}

@keyframes slideInLeftAndRotate {
    from {
        transform: translateX(-50%) rotate(12deg);
        opacity: 0;
    }
    to {
        transform: translateX(0) rotate(12deg);
        opacity: 1;
    }
}

@keyframes slideInLeftWithoutRotation {
    from {
        transform: translateX(-50%) rotate(12deg);
        opacity: 0;
    }
    to {
        transform: translateX(0) rotate(0);
        opacity: 1;
    }
}

.sm-plan-animate {
    animation: slideInLeftAndRotate 0.5s ease-out forwards;
}

@keyframes slideInBottomAndRotate {
    from {
        transform: translateY(50%) rotate(21deg);
        opacity: 0;
    }
    to {
        transform: translateY(0) rotate(-6deg);
        opacity: 1;
    }
}

@keyframes slideInRightWithoutRotation {
    from {
        transform: translateX(50%) rotate(-6deg);
        opacity: 0;
    }
    to {
        transform: translateX(0) rotate(0);
        opacity: 1;
    }
}

.pro-plan-animate {
    animation: slideInBottomAndRotate 0.5s ease-out forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes tada {
    from {
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}

.tada {
    animation-name: tada;
    animation-duration: 1s;
    /* Adjust duration as needed */
    animation-timing-function: ease-in-out;
}

.tada-view {
    animation-name: tada;
    animation-duration: 1s;
    /* Adjust duration as needed */
    animation-timing-function: ease-in-out;
    opacity: 1;
}

/* Responsive styles for smaller screens */

@media (min-width: 131.25rem) {
    .how-it-works-container {
        width: 80%;
    }
    .advantage-card-container {
        max-width: 60%;
    }
}

@media (max-width: 93.75rem) {
    .how-it-works-container {
        width: 100%;
    }
}


@media (max-width: 87.5rem) { /* 1400px */
    /* .hero-text {
        max-width: 60%;
    } */
    /* .hero-content {
        max-width: 50%;
    } */
    .steps-container {
        left: 3%;
    }
    .how-it-works-container {
        width: 100%;
    }
    .hero-wrapper {
        margin-top: 0;
    }
    .advantage-card-container {
        max-width: 85%;
    }
}

@media (max-width: 81.25rem) { /* 1300px */
    .hero-content {
        max-width: 54%;
    }
    .how-it-works-bg {
        width: 80%;
        /* position: relative; */
    }
}

@media (max-width: 75rem) { /* 1200px */
    /* .hero-text {
        margin-top: 3rem;
        max-width: 70%;
    } */
    .hero-image-icon {
        width: 50%;
    }
    .header-navigation {
        margin-left: 1.5rem;
    }
    .cta-button2 {
        display: none;
        /* hide navigation links and CTA button on mobile */
    }
    .advantage-card-container {
        gap: 2rem;
    }    
}

@media (max-width: 68.75rem) { /* 1100px */
    /* .hero-text {
        max-width: 80%;
    } */
    .hero-wrapper {
        flex-direction: column-reverse;
        align-items: center;
    }
    .hero-content {
        max-width: 90%;
        align-items: center;
    }
    .hero-screen {
        padding: 2rem;
    }
    /* .demo-video {
        padding: 4% calc(2rem + 4%) 0;
    } */
    .advantage-card-container {
        max-width: 90%;
    }
    .how-it-works-container {
        flex-direction: column;
        align-items: center;
    }
    .how-it-works-bg {
        width: 70%;
    }
    .steps-container {
        position: static;
        transform: none;
    }
    .steps-card {
        flex-direction: column;
        align-items: center;
    }
    .steps-card-content {
        text-align: center;
    }
    .plan-container {
        gap: 8rem;
    }

    .hero-text h1 {
        text-align: center;
    }
    
    .hero-text h3 {
        text-align: center;
    }

    .header-navigation {
        gap: 1rem;
    }

    .pricing-bg {
        margin-top: -18rem;
        /* margin-bottom: -12rem; */
    }
}

@media (max-width: 56.25rem) { /* 900px */
    .hero-content {
        max-width: 90%;
        padding: 0;
        padding-bottom: 2rem;
    }
    .hero-image-icon {
        width: 70%;
    }
    .advantage-card-container {
        grid-template-columns: repeat(2, 1fr);
    }
    

    .pricing-bg {
        margin-top: -15rem;
        /* margin-bottom: -10rem; */
    }
    .faq-question {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    .faq-item {
        padding: 1rem 1.2rem;
    }
    .burger-menu {
        display: block;
    }
    .header-navigation {
        display: none;
        /* hide navigation links and CTA button on mobile */
    }
    /* Adjusting navigation links for mobile */
    .nav-link {
        /* display: block; */
        padding: .5rem 1rem;
    }
    .header-content {
        justify-content: space-between;
    }
}

@media (max-width: 48rem) { /* 768p */
    /* .hero-text {
        max-width: 85%;
    } */
    .hero-text h1 {
        font-size: 3rem;
    }
    .hero-text h3 {
        font-size: 1.3rem;
    }
    .hero-image-icon {
        width: 85%;
    }
    .advantage-card-container {
        grid-template-columns: 1fr; /* One card per row */
        max-width: 70%;
    }
    .tutos-container {
        grid-template-columns: 1fr; /* One video per row */
    }
    .pro-plan-animate {
        margin-top: 0;
        animation: slideInRightWithoutRotation 0.5s ease-out forwards;
    }
    .sm-plan-animate {
        margin-top: 0;
        animation: slideInLeftWithoutRotation 0.5s ease-out forwards;
    }
    .plan-container {
        flex-direction: column;
        gap: 6rem;
    }
    .pricing {
        overflow: hidden;
    }
    .pricing-bg {
        margin-top: 0;
        /* margin-bottom: -6rem; */
    }

    .faq-container {
        width: 90%;
    }
}

@media (max-width: 37.25rem) { /* 500px */
    .hero-content {
        max-width: 100%;
    }
    .hero-text h1 {
        line-height: 110%;
    }
    .hero-text h3 {
        line-height: 130%;
    }
    .hero-image-icon {
        width: 100%;
    }
    .advantage-card-container {
        max-width: 85%;
    }
    .steps-card {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .steps-card-content {
        text-align: center;
    }
    .how-it-works-bg {
        width: 100%;
    }
    /* .pricing-bg {
        margin-bottom: -3rem;
    } */

    .faq-item {
        padding: .6rem;
    }

    .mobile-message > div {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 28.125rem) { /* 450px */
    .hero-image-icon {
        margin: -5rem;
    }
    .cta-button1 {
        max-width: 90%;
    }
    .plan {
        min-width: none;
        width: 90%;
    }
}